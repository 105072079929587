import { type Component, Show, For, createMemo, JSXElement } from "solid-js";
import { Dynamic } from "solid-js/web";
import { A, AccessorWithLatest } from "@solidjs/router";
import { Accordion } from "~/components/ui";
import fractionString from "~/utils/fraction";
import { Product } from "~/services/roma-api/products/types";


type ProductDetailsProps = {
  product: AccessorWithLatest<Product | undefined>,
  hideAvailableAs?:boolean;
  openOnLoad?:boolean;
  children?: JSXElement
}

export const ProductDetails: Component<ProductDetailsProps> = (props) => {
  // Dictionary for AvailbleAs -> determine what Box394 is
  const available: Record<string, string> = {
    chop: "Chop",
    box: "Box",
    cornerSample: "Corner Sample",
    join: "Join",
    length: "Length",
    rail: "Rail",
    mirror: "Mirror",
    photoFrame: "Photo Frame",
    galleryFrame: "Gallery Frame",
    contract: "Contract",
  };

  const linkableTypes: Record<string, string> = {
    mirror: "/mirror",
    photoFrame: "/photo-frame",
    galleryFrame: "/gallery-frame",
    contract: "?type=contract",
  };

  const attributes = createMemo(() => {
    if (!props.product()) return "";
    const arr = [
      props.product()?.Finish ? `${props.product()?.Finish} Finish` : undefined,
      props.product()?.Profile ? `${props.product()?.Profile} Profile` : undefined,
      props.product()?.Category
        ? `Collection ${props.product()?.Category}`
        : undefined,
    ]
      .filter(Boolean)
      .join(", ");
    return arr;
  });
  return (
    <Accordion
      name="details"
      label="Product Details"
      labelClass="font-bold"
      icon="Chevron"
      checked={props.openOnLoad}
      contentHeight="Large"
    >
      <>
        {props.children}
        <ul class="flex flex-col gap-3">
          <li class="flex flex-col gap-1">
            <h4 class="text-base font-medium">Dimensions</h4>
            <div class="flex flex-wrap gap-3 text-sm  text-roma-dark-grey">
              <Show when={props.product()?.Floater}>
                <p>
                  Depth
                  <span
                    class="fraction whitespace-nowrap"
                  >
                    {fractionString(props.product()?.Depth)}
                  </span>
                </p>
                <p>
                  Face
                  <span
                    class="fraction whitespace-nowrap"
                  >
                    {fractionString(props.product()?.Face)}
                  </span>
                </p>
              </Show>
              <p>
                Width
                <span
                  class="fraction whitespace-nowrap"
                >
                  {fractionString(props.product()?.Width)}
                </span>
              </p>
              <p>
                Height
                <span
                  class="fraction whitespace-nowrap"
                >
                  {fractionString(props.product()?.Height)}
                </span>
              </p>
              <Show when={props.product()?.Rabbet}>
                <p>
                  Rabbet Height
                  <span
                    class="fraction whitespace-nowrap"
                  >
                    {fractionString(props.product()?.Rabbet)}
                  </span>
                </p>
              </Show>
            </div>
          </li>
          <li class="flex flex-col gap-1">
            <h4 class="text-base font-medium">Attributes</h4>
            <div class="text-sm text-roma-dark-grey ">
              <p>{attributes()}</p>
            </div>
          </li>
          <Show when={!props.hideAvailableAs}>
            <li class="flex flex-col gap-1">
              <h4 class="text-base font-medium">Available As</h4>
              <div class=" text-sm text-roma-dark-grey overflow-x-auto flex flex-wrap gap-1">
                <For each={props.product()?.AvailableAs}>
                  {(type, index) => (
                    <span>
                      <Dynamic
                        component={linkableTypes[type] ? "A" : "span"}
                        class={`${linkableTypes[type] ? "text-roma-blue" : ""}`}
                        href={
                          linkableTypes[type]
                            ? `/product/${props.product()?.SKU}${linkableTypes[type]}`
                            : undefined
                        }
                      >
                        {available[type]}
                      </Dynamic>
                      {index() < props.product()?.AvailableAs.length! - 1
                        ? ", "
                        : ""}
                    </span>
                  )}
                </For>
              </div>
            </li>
          </Show>
          <li class="flex flex-col gap-1">
            <h4 class="text-base font-medium">Origin</h4>
            <div class="text-sm text-roma-dark-grey ">
              <A
                href="/about/made-in-italy"
                class="hover:text-roma-blue transition-[color] underline"
              >
                Made in Italy
              </A>
            </div>
          </li>
        </ul>
      </>
    </Accordion>
  );
};
