import { Switch, Match, Show, type Component } from "solid-js";
import { type AccessorWithLatest } from "@solidjs/router";
import { type Product } from "~/services/roma-api/products/types";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { PHOTOFRAME, GALLERYFRAME } from "~/utils/products";
import { Img } from "~/components/image";

export const ProductHeader: Component<{
  product: AccessorWithLatest<Product | undefined>;
  type?: string;
  size?: number | string;
  category?: string;
}> = (props) => {
  const { global } = useSiteContext();
  const { isPartner } = useSessionContext();

  return (
    <>
      <Show when={props.product()}>
        <div class="flex items-start justify-between">
          <h1 class="text-4xl font-semibold">{props.product()?.Collection}</h1>
        </div>
        <div class="flex items-center justify-between gap-2 pb-2">
          <div class="text-xl font-medium">
            <Switch fallback={<span>{props.product()?.SKU}</span>}>
              <Match when={props.type === PHOTOFRAME}>
                {props.product()?.SKU}
                <Show when={isPartner() && props.size}>
                  <span>-{props.size}</span>
                </Show>
              </Match>
              <Match when={props.type === GALLERYFRAME}>
                {props.product()?.SKU}
                <Show when={isPartner() && props.size}>
                  <span>GF-{props.size}</span>
                </Show>
              </Match>
            </Switch>
          </div>
          <div class="w-32">
            <Show when={props.product()?.Category && global()}>
              <Img
                src={global().categories?.[props.product()?.Category as string]}
                alt={props.product()?.Category}
                layout="fullWidth"
              />
            </Show>
          </div>
        </div>
      </Show>
    </>
  );
};
