import { type Component } from "solid-js";
import { Accordion } from "~/components/ui";

export const ProductDescription: Component<{ description: string }> = (
  props
) => {
  return (
    <Accordion
      name="description"
      label="Description"
      labelClass="font-bold"
      icon="Chevron"
      checked
    >
      <p class="text-sm text-roma-dark-grey leading-7">{props.description}</p>
    </Accordion>
  );
};
